import validator from "validator";

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export const validateAlphaNumeric = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(`${value}`)) callback(new Error('Must be alphanumeric'));
    else callback();
}

export const validateCountryIso = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(value)) callback(new Error('Must be alphanumeric'));
    if(value.length !== 2) callback(new Error('Exact 2 characters'))
    else callback();
}

export const validateCountryIso3 = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(value)) callback(new Error('Must be alphanumeric'));
    if(value.length !== 3) callback(new Error('Exact 3 characters'))
    else callback();
}

export const validateUUIDToken = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isUUID(value)) callback(new Error('Wrong token format'));
    else callback();
}

export const validatePass = (rule, value, callback) => {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    if(!rule.required && value === '') return callback()
    if(value === '') callback();
    else if (value !== '' && !regex.test(value)) callback(new Error('Illegal Password'));
    else callback();
};

export const validateEmail = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isEmail(value)) callback(new Error('Wrong email format'));
    else callback();
}

export const validateIP = (rule, value, callback) => {
    console.log('------', validator.isIP(value))

    if(!rule.required && value === '') return callback()
    if(value === '') callback();
    else if(value !== '' && !validator.isIP(value)) callback(new Error('Illegal IP Format'));
    else callback();
}

export const validateIPArray = (rule, value, callback) => {
    if(value.length === 0) callback();
    else {
        for (const v of value) {
            if (!validator.isIP(v)) callback(new Error('Illegal IP Format'));
        }
    }
    callback();
}

export const validateHref = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    if(!validator.isURL(value)) callback(new Error('Wrong URL format!'));
    else callback();
}

export const validateSelect = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Must select an option`));
    else callback();
}

export const notEmpty = (rule, value, callback) => {
    if(value === '') callback(new Error("Field must be set"));
    else callback();
};

export const validateNumeric = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    if(!validator.isNumeric(value)) callback(new Error('Must be numeric'));
    else callback();
}

export const validateJSON = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    try {
        let a = JSON.parse(value);
        callback();
    } catch (e) {
        callback(new Error('Invalid JSON Format'));
    }
}