<template lang="pug">
	el-row()
		el-col(:span="24")
			p Use this form to update details of the lead. Use this form with care as it will change the details of the lead without the ability to revert the action.
			p Current Details are:
		el-form.lum-details-form()
			el-row(:gutter="24")
				el-col(:span="6")
					el-form-item(label="First_name")
						span.text-danger {{ details.first_name }}
				el-col(:span="6")
					el-form-item(label="First_name")
						span.text-danger {{ details.last_name }}
				el-col(:span="6")
					el-form-item(label="E-Mail")
						span.text-danger {{ details.email }}
				el-col(:span="6")
					el-form-item(label="Phone")
						span.text-danger {{ details.phone }}
				el-col(:span="6")
					el-form-item(label="Country")
						span.text-danger {{ details.country }}
				el-col(:span="6")
					el-form-item(label="IP Address")
						span.text-danger {{ details.ip_addr }}
				el-col(:span="12")
					el-form-item(label="Customer ID")
						span.text-danger {{ details.cid }}
				el-col(:span="12")
					el-form-item(label="Query Params")
						span.text-danger {{ details.params }}

		el-divider
		el-col(:span="24")
			el-form(ref="form" :model="form" :rules="rules" layout="vertical" )
				el-row.mt-3()
					h5 Basic Details - Type to update
					p Instruction - Using this tool you will overwrite the values that are currently stored in the database. To set NULL - use <b class="text-danger">::NULL::</b><br>
						| Removing a CID will lead to the customer being a failed one and vice versa - a failed customer with a phone and a CID is a legit customer. Use with care.
				el-row(:gutter="12")
					el-col(:xs="12" :sm="6")
						el-form-item(has-feedback="" label="First Name" prop="first_name")
							el-input(v-model="form.first_name" size="mini")
					el-col(:xs="12" :sm="6")
						el-form-item(has-feedback="" label="First Name" prop="last_name")
							el-input(v-model="form.last_name" size="mini")
					el-col(:xs="24" :sm="12")
						el-form-item(has-feedback="" label="E-Mail" prop="email")
							el-input(v-model="form.email" size="mini")
					el-col(:xs="12" :sm="6")
						el-form-item(has-feedback="" label="Phone" prop="phone")
							el-input(v-model="form.phone" size="mini")
					el-col(:xs="12" :sm="6")
						el-form-item(has-feedback="" label="Country" prop="country")
							el-select.w-100(v-model="form.country" size="mini")
								el-option(v-for="o of country_options" :value="o.v" :label="o.t")
					el-col(:xs="12" :sm="6")
						el-form-item(has-feedback="" label="IPv4" prop="ip_addr")
							el-input(v-model="form.ip_addr" size="mini")
					el-col(:xs="12" :sm="6")
						el-form-item(has-feedback="" label="CID (CustomerID)" prop="cid")
							el-input(v-model="form.cid" size="mini")
				el-row()
					h5 Params - JSON
				//el-row()
					el-col(:span="24")
						el-form-item(label="Query Params (JSON)" prop="params")
							el-input(v-model="form.params" size="mini")
				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Override Parameters")
							el-button(type="info" icon="el-icon-plus" @click="addRow()") Add
						.w-100
							el-row.mt-1.mb-1(v-for="(o, i) of params_arr" :gutter="6")
								el-col.mb-sm-1(:sm="10" :md="8")
									el-input.sm-gap-1(v-model="o.key" placeholder="Key")
								el-col.mb-sm-1(:sm="12" :md="14")
									el-input.sm-gap-1(v-model="o.value" placeholder="Value")
								el-col.mb-sm-1(:xs="3" :sm="2" :md="1")
									el-button.sm-gap-1(icon="el-icon-minus" type="danger" @click="() => params_arr.splice(i,1)")


</template>

<style lang="scss">
.lum-details-form {
		.el-form-item__label, .el-form-item__content {
				line-height: 20px
		}
}
</style>

<script>
import {
		notEmpty,
		validateAlphaNumeric,
		validateCountryIso,
		validateEmail,
		validateIP, validateJSON,
		validateNumeric
} from "../utils/validate";

const $form = {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		country: '',
		ip_addr: '',
		cid: '',
		params: {}
}

function sanitize(data) {
		Object.keys(data).forEach(e => {
				console.log(data[e], e)
				if(data[e] === '') delete data[e]
		})
}

export default {
		name: 'lead-update',
		props: [
				'details',
				'country_options'
		],
		data() {
				return {
						form: $form,
						params_arr: [],
						params_counter: 0,
						rules: {
								first_name: { required: false, trigger: 'blur' },
								last_name: { required: false, trigger: 'blur' },
								email: { required: false, validator: validateEmail, trigger: 'blur' },
								phone: { required: false, validator: validateNumeric, trigger: 'blur' },
								country: { required: false, validator: validateCountryIso, trigger: 'blur' },
								ip_addr: { required: false, validator: validateIP, trigger: 'blur' },
								cid: { required: false, validator: validateAlphaNumeric, trigger: 'blur' },
								params: { required: false, validator: validateJSON, trigger: 'blur' },
						}
				}
		},
		mounted() {
			this.form = JSON.parse(JSON.stringify($form));
				Object.entries(this.details.params).forEach(e => this.params_arr.push({ key: e[0], value: e[1] }))
		},

		methods: {
				submit() {
						this.$refs.form.validate(async valid => {
								let params = {};
								this.params_arr.forEach(e => params[e.key] = e.value);
								const payload = Object.assign({}, sanitize(this.form));
								this.form.params = params;
								if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
								this.$apix.sendHttpRequest('POST', 'lead/edit/' + this.details.uuid, this.form)
										.then(res => {
												let message = res.code ? 'Lead\'s details were successfully updated' : 'Nothing was updated'
												this.$message[res.code ? 'success' : 'info']({ title: 'API Response', message })
												this.$emit('success', res.code)
										});
						});
				},
				addRow() {
						this.params_arr.push({key: `key_${this.params_counter}`, value: `value_${this.params_counter}`});
						this.params_counter++;
				},
		}
}
</script>